import React from "react"
import styled from "styled-components"
import moment from "moment"

const Box = styled.div`
  border-radius: 3px;
  border: 1px solid black;
  box-shadow: 0 3px #e5e5e5;
  display: grid;
  height: 130px;
  padding: 5px;
  width: 100%;
`

const Wrapper = styled.div`
  display: grid;
  font-family: "Roboto";
  grid-gap: 10px;
  grid-template-columns: repeat(7, auto);
  grid-template-rows: auto;
  margin: auto;
  padding: 20px;
  row-gap: 5px;
  width: 100%;
`

const Date = styled.div`
  font-size: 18px;
  p {
    margin-bottom: 0;
    line-height: 20px;
  }
`

const Trackers = styled.div`
  align-items: center;
  border: 1px solid black;
  display: flex;
  font-size: 30px;
  justify-content: center;
  margin: 5px;
  width: 40px;
`

const HabitTracker = props => {
  return (
    <>
      {/* <h2 style={{ textAlign: "center" }}>Habit Builder</h2> */}
      <Wrapper>
        {Array.from(Array(84).keys(), n => n + 1).map((i, index) => {
          const week = Math.floor(index / 7 + 1)
          return (
            <Box>
              <Date>
                <p>
                  <b>
                    Day {index + 1} {index % 7 === 0 && ` - Week ${week}`}
                  </b>
                </p>
                <p>
                  <i>{moment().add(index, "days").format("dddd D MMM")}</i>
                </p>
              </Date>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Trackers>🥗</Trackers>
                <Trackers>📘</Trackers>
                <Trackers>👨‍💻</Trackers>
                <Trackers>🏋️‍♀️</Trackers>
                <Trackers>🧘‍♂️</Trackers>
              </div>
            </Box>
          )
        })}
      </Wrapper>
    </>
  )
}

export default HabitTracker
